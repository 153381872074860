import React, { useEffect, useRef } from 'react'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { OPEN_CONTACT_MODAL } from '../consts'
import Popup from 'reactjs-popup'
import { ArrowRight, X } from 'react-feather'
import Button from './atoms/Button'
import ContactForm from '../components/estimate-form'
import { useAnimation, motion, useInView, stagger } from "framer-motion";

const FeaturedColumns = (data) => {


  const dataColumnsCount = data.data.map((item, index) => (
    <FeaturedColumn key={index} index={index} item={item} columnsCount={data.data.length} />
  ))
  return dataColumnsCount
}



const FeaturedColumn = (data) => {

  const squareVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: .5, delay: data.index / 8 } },
    hidden: { opacity: 0, y: 20 }
  };


  const learnMoreRef = useRef(null)
  const buttonProps = {}

  const controls = useAnimation();
  const ref = useRef(null)

  const inView = useInView(ref)


  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);


  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariants}
      key={data.item.slug} className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl shadow px-8 pb-8 pt-80 sm:pt-48 lg:pt-72">
      <GatsbyImage
        className="absolute inset-0 -z-10 h-full w-full object-cover absolute-important"
        image={getImage(data.item.heroImage)}
        style={{ objectFit: 'cover' }}
        alt={data.item.serviceTitle + ' Contractor'}
      />
      <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />


      <div className="flex-1 relative pt-4 px-2 pb-4 md:px-4" >

        <h3 className="text-xl font-bold text-white">{data.item.featuredLabel
          ? data.item.featuredLabel
          : data.item.serviceTitle}</h3>
        <p className="mt-4 text-base text-gray-200">{data.item && data.item.shortExcerpt}</p>
      </div >
      <div className=" pb-4  rounded-br-xl  px-2 md:px-4">

        <Button
          class="text-white hover:bg-gray-100  "
          to={data.item.slug}
          title={'Learn more about ' + data.item.serviceTitle}
        >
          Learn more <ArrowRight size={18} className="ml-2" />
        </Button>

      </div>
    </motion.div >

  )
}

const FeaturedItems = ({ data }) => {
  return (
    <div className='bg-gray-50'>
      <section
        className=" max-w-7xl mx-auto relative py-16 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h4 className="sr-only" id="contact-heading">
          Featured Services
        </h4>
        <h3 className='font-display font-extrabold text-3xl text-brand-700  md:text-3xl '>KYPD - Where Quality and Urgency Matter</h3>
        <p className=' mb-6 pt-4 text-base md:text-lg text-brand-400  md:leading-7  '>Our mission is to provide excellent workmanship and complete customer satisfaction from start to completion of a project.</p>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3  lg:gap-6">
          <FeaturedColumns data={data} />

        </div>
      </section></div>

  )
}
export default FeaturedItems

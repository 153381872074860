import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Button from './atoms/Button'

const incentives = [
    {
        name: 'Initial Consultation',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg',
        description: "Our team will meet with you to discuss your renovation project, including your goals, budget, and timeline.",
    },
    {
        name: 'Design and Planning',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg',
        description: "Our team will work with you to create a custom design that meets your unique needs and tastes.",
    },
    {
        name: 'Construction',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description:
            "Our team of experts will handle all aspects of the construction process, including demolition, installation, and finishing.",
    },
    {
        name: 'Final Walkthrough',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description:
            "Once complete, we will conduct a final walkthrough with you to ensure that you are completely satisfied with the finished product.",
    },
]
export const OurPartners = () => {
    return (
        <div className="bg-white py-24">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
                    <div className="mx-auto w-full max-w-xl lg:mx-0">
                        <h4 className="text-3xl font-bold tracking-tight text-gray-900">Our Partners</h4>
                        <p className="mt-6 text-base  text-gray-600">
                            We take pride in delivering top-notch plumbing services to our clients. Our commitment to excellence and customer satisfaction has helped us build a solid reputation over the years.<br /><br />
                            Don't just take our word for it, though. Take a look at some of the companies we've had the pleasure of working with in the past. We're proud to have provided plumbing solutions to these respected brands and businesses.

                        </p>
                        <div className="mt-8 flex items-center gap-x-6">
                            <Button to='/services'>Explore our services</Button>
                        </div>
                    </div>
                    <div className="mx-auto grid w-full max-w-xl grid-cols-4 items-center gap-12 lg:mx-0 lg:max-w-none lg:pl-8">

                        <StaticImage src="../images/client/McDonalds_SVG_logosv.png" className="w-full object-contain object-left" alt="Client logo" />
                        <StaticImage src="../images/client/NashBlackGoldlogo202.png" className="w-full object-contain object-left" alt="Client logo" />
                        <StaticImage src="../images/client/OrgAdjusted_PNG.png" className="w-full object-contain object-left" alt="Client logo" />
                        <StaticImage src="../images/client/max-construction-com.png" className="w-full object-contain object-left" alt="Client logo" />
                        <StaticImage src="../images/client/logo-rebrand.png" className="w-full object-contain object-left" alt="Client logo" />
                        <StaticImage src="../images/client/conrad.png" className="w-full object-contain object-left" alt="Client logo" />
                        <StaticImage src="../images/client/c59a1524-484e-4477-9.png" className="w-full object-contain object-left" alt="Client logo" />
                        <StaticImage src="../images/client/BluegrassHaven_Logo.png" className="w-full object-contain object-left" alt="Client logo" />

                    </div>
                </div>
            </div>
        </div>

    )
}
